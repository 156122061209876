import React from 'react'
import "../assets/css/productsdata.css";

const ProductsData = () => {
  return (
    <div className='products_data_blk'>
    <h1>Our More Products</h1>
    <div className='produtcs_details_data'>
      SHACKLE PINS / COTTER PINS / KING PINS ,SG Iron, Cast Iron, Alloy Steels, Investment Castings, and Aluminium Castings
      Bushes, Sleeves, Washers, Earth mover's components, Die Makers
      </div>
    </div>
  )
}

export default ProductsData
